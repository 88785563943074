<template>
  <div>
    <Header />
    <!--Beneficios-->
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios" id="DivBtnVoltaBeneficios">
        <a
          @click="Show_Info()"
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid">
        <div class="col-md-6">
          <!--<h2 class="HeaderTitulo1 TituloCor1 BackgroundColor2">VISÃO SIMPLES</h2>-->

          <div class="rowBlock">
            <h1 class="TituloCor1">
              Lentes<br />
              Fotossensíveis
            </h1>

            <h1 class="TituloCor1 Hide" id="TituloProgressivas">Premium</h1>

            <!--<h2 class="HeaderTitulo1 TituloCor1 BackgroundColor2 Hide" id="TituloVisaoSimples"></h2>-->

            <a
              href="javascript:void(0)"
              class="BtnFechaBeneficios"
              id="BtnFechaBeneficios"
            >
              <img
                @click="Hide_Info()"
                src="./svg/CloseBeneficios.svg"
                class="IconeClose"
                alt="CLOSE"
              />
            </a>
          </div>

          <div class="DivEscolhas">
            <div class="row">
              <a
                @click="Open_Modal()"
                href="javascript:void(0)"
                class="ShowBeneficios"
                id="AbreModalBeneficios"
              >
                <img src="./svg/OpenCollapse.svg" class="" alt="Saiba mais" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <!--Modal Beneficios-->
    <div class="ModalBeneficios" id="ModalBeneficios">
      <div class="HeaderModal">
        <h2 class="TituloCor1">Lentes Fotossensíveis</h2>
      </div>

      <a
        @click="Close_Modal()"
        href="javascript:void(0)"
        class="BtnCloseModal"
        id="FechaModalBeneficios"
      >
        <img src="./svg/CloseModal.svg" alt="Fechar" />
      </a>

      <div class="container ContainerModal">
        <div class="col-12 NoPadding">
          <div class="DivModalBeneficios" id="DivModalBeneficios">
            <carousel
              :autoplay="false"
              :loop="false"
              :nav="true"
              :dots="true"
              :responsive="{
                0: { items: 1, nav: false },
                450: { items: 1, nav: true },
                1000: { items: 1, nav: true },
              }"
            >
              <div class="item">
                <div class="row">
                  <img
                    src="./images/Fotossensiveis/Banner_Fotossensiveis_3.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    Sob a luz do sol, os olhos sofrem com a exposição aos raios
                    UV, dessa forma as lentes fotossensíveis podem ajudar na
                    proteção dos olhos pois filtram estes raios nocivos,
                    bloqueando 100% dos raios UVA/UVB.<br />
                    Não se trata apenas de conforto visual ou estética mas
                    também de proteção e prevenção de doenças como catarata e
                    degeneração macular.
                  </p>
                </div>
              </div>

              <div class="item">
                <div class="row">
                  <img
                    src="./images/Fotossensiveis/Banner_Fotossensiveis_1.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    As lentes fotossensíveis tem a incrível capacidade de
                    adaptar-se à luminosidade do ambiente que o usuário se
                    encontra - ficando na sua cor natural em ambientes fechados
                    com ou sem luz artificial ou escuras em locais abertos com a
                    incidência da luz do sol. Dessa forma são duas lentes em um
                    só produto.
                  </p>
                </div>
              </div>

              <div class="item">
                <img
                  src="./images/Fotossensiveis/Banner_Fotossensiveis_2.png"
                  class="img-fluid"
                />

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    Lentes com estas características possuem moléculas que
                    reagem aos raios UV. Quando atingidas por estes raios, estas
                    moléculas são ativadas e liberadas, escurecendo as lentes e
                    quanto mais intensos forem os raios UV, mais escuras
                    ficarão.
                  </p>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <!--Modal Beneficios-->

    <!--Menu Cores-->
    <div class="MenuCores Oculta_MenuCores" id="MenuCores">
      <div
        class="PortaConteudo d-flex justify-content-center align-items-start"
      >
        <div class="scrollTo scrollTo_MarginInicio">
          <a  rel="ConteudoFotossensivel">
            <img
              src="./svg/Lampada.svg"
              class="IconeScrollTo_Inicio"
              alt="Ambiente Interno"
              @click="rightToLeft()"
            />
          </a>
        </div>

        <div class="PortaCores scrollTo_Cores">
          <div class="col bottom10 text-center">
            <p class="SmallTxt Blue1">
              <strong>SELECIONE UMA DAS CORES:</strong>
            </p>
          </div>

          <a
            href="javascript:void(0)"
            rel="ConteudoFotossensivel"
            class="Ativa_Cinza"
            id="Ativa_Cinza"
            @click="Ativa_Cinza()"
          >
            <img
              src="./images/Fotossensiveis/Cor_Cinza.png"
              class="ImagemCores"
              alt="Cinza"
            />
          </a>

          <a
            href="javascript:void(0)"
            rel="ConteudoFotossensivel"
            class="Ativa_Marrom"
            id="Ativa_Marrom"
            @click="Ativa_Marrom()"
          >
            <img
              src="./images/Fotossensiveis/Cor_Marrom.png"
              class="ImagemCores"
              alt="Marrom"
            />
          </a>

          <a
            href="javascript:void(0)"
            rel="ConteudoFotossensivel"
            class="Ativa_Verde"
            id="Ativa_Verde"
            @click="Ativa_Verde()"
          >
            <img
              src="./images/Fotossensiveis/Cor_Verde.png"
              class="ImagemCores"
              alt="Verde"
            />
          </a>

          <a
            href="javascript:void(0)"
            rel="ConteudoFotossensivel"
            class="Ativa_Safira"
            id="Ativa_Safira"
            @click="Ativa_Safira()"
          >
            <img
              src="./images/Fotossensiveis/Cor_Safira.png"
              class="ImagemCores"
              alt="Safira"
            />
          </a>

          <a
            href="javascript:void(0)"
            rel="ConteudoFotossensivel"
            class="Ativa_Ametista"
            id="Ativa_Ametista"
            @click="Ativa_Ametista()"
          >
            <img
              src="./images/Fotossensiveis/Cor_Ametista.png"
              class="ImagemCores"
              alt="Ametista"
            />
          </a>

          <a
            href="javascript:void(0)"
            rel="ConteudoFotossensivel"
            class="Ativa_Ambar"
            id="Ativa_Ambar"
            @click="Ativa_Ambar()"
          >
            <img
              src="./images/Fotossensiveis/Cor_Ambar.png"
              class="ImagemCores"
              alt="Ambar"
            />
          </a>

          <div class="col text-center top10">
            <p class="SmallTxt" id="NomeDaCor">Cor selecionada: CINZA</p>
          </div>
        </div>

        <div class="scrollTo scrollTo_MarginFinal">
          <a rel="ConteudoFotossensivel">
            <img
              src="./svg/Sol.svg"
              class="IconeScrollTo_Final"
              alt="Ambiente Externo"
              @click="leftToRight()"
            />
          </a>
        </div>
      </div>

      <div class="row RowBlock" style="display:contents">
        <a
          href="javascript:void(0)"
          class="OcultarMenuCores Hide"
          id="OcultarMenuCores"
          @click="OcultarMenuCores()"
        >
          <img
            src="./svg/Fotossensiveis_Close.svg"
            class="BtnOcultar"
            alt="Ocultar"
          />
        </a>

        <a
          href="javascript:void(0)"
          class="MostrarMenuCores"
          id="MostrarMenuCores"
          @click="MostrarMenuCores()"
        >
          <img
            src="./svg/Fotossensiveis_Show.svg"
            class="BtnMostrar"
            alt="Mostrar"
          />
        </a>
      </div>
    </div>
    <!--Menu Cores-->
  
    <!--Aviso Scroll-->
    <!--
    NOT WORKING ANYMORE
    <div class="AvisoScroll">
      <div class="row RowBlock text-center">
        <img
          src="./images/Fotossensiveis/GifScroll.gif"
          class="GifScroll"
          alt="Scroll"
        />

        <p class="White">
          Utilize o SCROLL do mouse ou a barra de rolagem abaixo para navegar
          pela imagem
        </p>
      </div>
    </div>
    -->
    <!--Aviso Scroll-->

    <!--Armação com Lentes-->
    <div id="" class="DivFull_CenterCenter DivLente">
      <img
        src="./images/Fotossensiveis/Fotossensivel_Incolor.png"
        class="Lente_Incolor"
        alt="LenteIncolor"
      />

      <img
        src="./images/Fotossensiveis/Fotossensivel_Cinza.png"
        class="Lente_Ativada Hide_Lente"
        id="Lente_Cinza"
        alt="LenteAtivada"
      />

      <img
        src="./images/Fotossensiveis/Fotossensivel_Marrom.png"
        class="Lente_Ativada Hide_Lente"
        id="Lente_Marrom"
        alt="LenteAtivada"
      />

      <img
        src="./images/Fotossensiveis/Fotossensivel_Verde.png"
        class="Lente_Ativada Hide_Lente"
        id="Lente_Verde"
        alt="LenteAtivada"
      />

      <img
        src="./images/Fotossensiveis/Fotossensivel_Safira.png"
        class="Lente_Ativada Hide_Lente"
        id="Lente_Safira"
        alt="LenteAtivada"
      />

      <img
        src="./images/Fotossensiveis/Fotossensivel_Ametista.png"
        class="Lente_Ativada Hide_Lente"
        id="Lente_Ametista"
        alt="LenteAtivada"
      />

      <img
        src="./images/Fotossensiveis/Fotossensivel_Ambar.png"
        class="Lente_Ativada Hide_Lente"
        id="Lente_Ambar"
        alt="LenteAtivada"
      />
    </div>
    <!--Armação com Lentes-->

    <!--Scroll Horizontal-->
    <div id="ScrollFotossensivel" class="showcase scrollTo-demo">
      <section id="ScrollDhell">
        <div
          id="ConteudoFotossensivel"
          class=" horizontal-images demo-x ">
        
         
              <img
                src="./images/Fotossensiveis/Background_Fotossensiveis.jpg"
                alt="Imagem"
                id="ImagemScroll"
                style="height:100vh;background-position: center center no-repeat"
              />
       
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Header from '@/views/menu/components/header.vue'
import carousel from 'vue-owl-carousel'

import {
  OcultarMenuCores,
  MostrarMenuCores,
  Ativa_Cinza,
  Ativa_Marrom,
  Ativa_Verde,
  Ativa_Safira,
  Ativa_Ametista,
  Ativa_Ambar,
  rightToLeft,
  leftToRight
} from '@/views/menu/js/new/Photosensitive_Script.js'

import {
  Hide_Info,
  Show_Info,
  Open_Modal,
  Close_Modal,
} from '@/views/menu/js/new/Scripts'

export default {
  components: {
    Header,
    carousel,
  },
  methods: {
    Hide_Info,
    Show_Info,
    Open_Modal,
    Close_Modal,

    OcultarMenuCores,
    MostrarMenuCores,

    Ativa_Cinza,
    Ativa_Marrom,
    Ativa_Verde,
    Ativa_Safira,
    Ativa_Ametista,
    Ativa_Ambar,
    rightToLeft,
    leftToRight
  },
}
</script>

<style scoped>
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import "./css/plugins/owl.carousel.min.css"; */
@import './css/plugins/owl.theme.default.min.css';

@import './css/Fotossensiveis_Style.css';

@import './css/plugins/scrollHorizontal.css';
/* 
@import './css/plugins/jquery.mCustomScrollbar.css'; */

/* 
src="./images/Fotossensiveis/Background_Fotossensiveis_Dentro.jpg"
src="./images/Fotossensiveis/Background_Fotossensiveis_Fora.jpg"
*/

.Hide {
  display: none;
}
</style>
