export function OcultarMenuCores() {
  document.getElementById('MenuCores').classList.add('Oculta_MenuCores')

  document.getElementById('OcultarMenuCores').classList.add('Hide')

  document.getElementById('MostrarMenuCores').classList.remove('Hide')
}

export function MostrarMenuCores() {
  document.getElementById('MenuCores').classList.remove('Oculta_MenuCores')

  document.getElementById('OcultarMenuCores').classList.remove('Hide')

  document.getElementById('MostrarMenuCores').classList.add('Hide')
}


export function rightToLeft(){
  document.getElementById('ImagemScroll').classList.add('moveLeft')
  document.getElementById('ImagemScroll').classList.remove('moveRight')
}

export function leftToRight(){
  document.getElementById('ImagemScroll').classList.add('moveRight')
  document.getElementById('ImagemScroll').classList.remove('moveLeft')
}

export function Ativa_Cinza() {
  document.getElementById('ImagemScroll').classList.remove('moveLeft')

  document.getElementById('ImagemScroll').classList.add('moveRight')

  document.getElementById('Lente_Cinza').classList.remove('Hide_Lente')

  document.getElementById('Lente_Marrom').classList.add('Hide_Lente')

  document.getElementById('Lente_Verde').classList.add('Hide_Lente')

  document.getElementById('Lente_Safira').classList.add('Hide_Lente')

  document.getElementById('Lente_Ametista').classList.add('Hide_Lente')

  document.getElementById('Lente_Ambar').classList.add('Hide_Lente')

  document.getElementById('NomeDaCor').innerHTML = 'Cor selecionada: CINZA'
}

export function Ativa_Marrom() {
  document.getElementById('ImagemScroll').classList.remove('moveLeft')

  document.getElementById('ImagemScroll').classList.add('moveRight')

  document.getElementById('Lente_Marrom').classList.remove('Hide_Lente')

  document.getElementById('Lente_Cinza').classList.add('Hide_Lente')

  document.getElementById('Lente_Verde').classList.add('Hide_Lente')

  document.getElementById('Lente_Safira').classList.add('Hide_Lente')

  document.getElementById('Lente_Ametista').classList.add('Hide_Lente')

  document.getElementById('Lente_Ambar').classList.add('Hide_Lente')

  document.getElementById('NomeDaCor').innerHTML = 'Cor selecionada: MARROM'
}

export function Ativa_Verde() {
  document.getElementById('ImagemScroll').classList.remove('moveLeft')

  document.getElementById('ImagemScroll').classList.add('moveRight')
  document.getElementById('Lente_Verde').classList.remove('Hide_Lente')

  document.getElementById('Lente_Marrom').classList.add('Hide_Lente')

  document.getElementById('Lente_Cinza').classList.add('Hide_Lente')

  document.getElementById('Lente_Safira').classList.add('Hide_Lente')

  document.getElementById('Lente_Ametista').classList.add('Hide_Lente')

  document.getElementById('Lente_Ambar').classList.add('Hide_Lente')

  document.getElementById('NomeDaCor').innerHTML = 'Cor selecionada: VERDE'
}

export function Ativa_Safira() {
  document.getElementById('ImagemScroll').classList.remove('moveLeft')

  document.getElementById('ImagemScroll').classList.add('moveRight')
  document.getElementById('Lente_Verde').classList.add('Hide_Lente')

  document.getElementById('Lente_Marrom').classList.add('Hide_Lente')

  document.getElementById('Lente_Cinza').classList.add('Hide_Lente')

  document.getElementById('Lente_Safira').classList.remove('Hide_Lente')

  document.getElementById('Lente_Ametista').classList.add('Hide_Lente')

  document.getElementById('Lente_Ambar').classList.add('Hide_Lente')

  document.getElementById('NomeDaCor').innerHTML = 'Cor selecionada: SAFIRA'
}

export function Ativa_Ametista() {
  document.getElementById('ImagemScroll').classList.remove('moveLeft')

  document.getElementById('ImagemScroll').classList.add('moveRight')
  document.getElementById('Lente_Verde').classList.add('Hide_Lente')

  document.getElementById('Lente_Marrom').classList.add('Hide_Lente')

  document.getElementById('Lente_Cinza').classList.add('Hide_Lente')

  document.getElementById('Lente_Safira').classList.add('Hide_Lente')

  document.getElementById('Lente_Ametista').classList.remove('Hide_Lente')

  document.getElementById('Lente_Ambar').classList.add('Hide_Lente')

  document.getElementById('NomeDaCor').innerHTML = 'Cor selecionada: AMETISTA'
}

export function Ativa_Ambar() {
  document.getElementById('ImagemScroll').classList.remove('moveLeft')

  document.getElementById('ImagemScroll').classList.add('moveRight')
  document.getElementById('Lente_Verde').classList.add('Hide_Lente')

  document.getElementById('Lente_Marrom').classList.add('Hide_Lente')

  document.getElementById('Lente_Cinza').classList.add('Hide_Lente')

  document.getElementById('Lente_Safira').classList.add('Hide_Lente')

  document.getElementById('Lente_Ametista').classList.add('Hide_Lente')

  document.getElementById('Lente_Ambar').classList.remove('Hide_Lente')

  document.getElementById('NomeDaCor').innerHTML = 'Cor selecionada: AMBAR'
}
